




































import { Vue, Component } from 'vue-property-decorator'
import { ColumnConfig } from '@/components/table/types'
import Add from './add.vue'
@Component({
  components: {
    Add
  }
})
export default class community extends Vue {
  tableData = [];
  total = 0;
  loading = false;
  dialogVisible = false;
  formInline = {
    name: '',
    region: '',
    status: ''
  };

  current = 1;

  onSearch (): void {
    this.current = 1
    this.fetch()
  }

  onReSet () {
    this.formInline = {
      name: '',
      region: '',
      status: ''
    }
    this.fetch()
  }

  column: Array<ColumnConfig> = [
    {
      label: '名称',
      code: 'name'
    },
    {
      label: '所在省/市/区',
      code: 'address'
    },
    {
      label: '用户量',
      code: 'userCount'
    },
    {
      label: '被关注量',
      code: 'followCount'
    },
    {
      label: '创建日期',
      code: 'createTime'
    },
    {
      label: '状态',
      code: 'status',
      scopedSlots: 'status'
    },
    {
      label: '操作',
      key: 'action',
      options: [
        {
          title: '冻结',
          click: (row: any) => {
            this.updateCommunity(row.id, 2)
          }
        },
        {
          title: '解冻',
          click: (row: any) => {
            this.updateCommunity(row.id, 1)
          }
        },
        {
          title: '删除',
          click: (row: any) => {
            this.updateCommunity(row.id, 0)
          }
        }
      ]
    }
  ];

  async updateCommunity (id: number, type: number) {
    if (type === 0) {
      await this.$confirm('此操作将删除该小区, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      await this.$Post(this.$Api.updateCommunity, {
        id,
        type
      })
      this.fetch()
      this.$message({
        type: 'success',
        message: '删除成功!'
      })
    } else {
      this.$Post(this.$Api.updateCommunity, {
        id,
        type
      })
      this.fetch()
    }
  }

  addSuccess () {
    this.fetch()
  }

  changePagination (current: any) {
    this.current = current
    this.fetch()
  }

  async fetch () {
    this.loading = true
    const r = await this.$Get(this.$Api.communityListPage, {
      pageSize: 10,
      current: this.current,
      ...this.formInline
    })
    this.tableData = r.data.records
    this.total = r.data.total
    this.loading = false
  }

  mounted () {
    this.fetch()
  }
}
